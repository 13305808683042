.navbar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  /* padding: 0px 15px; */
  height: 75px;
  width: 100%;
}

.navbar-core {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 1rem;
}
.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 1rem;
  width: 100%;
}
.navbar-actions {
  display: flex;
}
.navbar-user-profile-logo {
  height: fit-content;
  width: 60px;
  margin-left: 10px;
}

.navbar-logo-image {
  /* width: 200px; */
  height: 70px;
  padding: 0.5rem;
}
.navbar-links_logo {
  justify-content: space-between;
  display: flex;
  /* padding: 1rem 0rem; */
  align-items: center;
  height: 100%;
  position: relative;
}

.navbar-links_logo p {
  color: var(--font-color);
  font-weight: 900;
  font-family: var(--font-family-montserrat);
  font-size: 20px;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-right: 15px;
  cursor: pointer;
}

.navbar-links_container {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
}

.navbar-sign {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container p {
  color: var(--font-color);
  font-family: var(--font-family-manrope);
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0rem 1rem;
  cursor: pointer;
}
.navbar-links_container p {
  color: white;
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}
.navbar-links_container:last-child {
  gap: 1rem;
  align-items: center;
}

.navbar-sign button,
.navbar-menu_container button,
.navbar-menu_container-link-sign button {
  padding: 0.5rem 0.5rem;
  color: var(--font-color);
  background-color: transparent;
  background-image: url(../../assets//BGs/BG\ Plain.png);

  box-shadow: 0px 0px 10px 0px rgb(0, 0, 0);
  font-family: var(--font-family-manrope);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.navbar-menus {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.navbar-menus svg {
  cursor: pointer;
  display: none;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 99999999999;
  text-align: end;
  background-color: transparent;
  border: 1rem;
  border-color: white;
  backdrop-filter: blur(18px);
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 0.5rem;
  min-width: 150px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgb(0, 0, 0);
}
.hidden {
  display: none;
}
.navbar-menu_container p {
  margin: 0;
}
.navbar-menu_container-links:only-child {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}
.navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .navbar-links_container {
    display: none;
  }

  .navbar-menus {
    display: flex;
  }
  .navbar-menus svg {
    display: flex;
  }
}

@media screen and (max-width: 870px) {
  .navbar {
    padding: 1rem 1rem;
  }
}

@media screen and (max-width: 550px) {
  .navbar-sign {
    display: none;
  }

  .navbar-menu_container {
    top: 25px;
  }

  .navbar-menu_container-link-sign {
    display: block;
  }
}

.navbar-home-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-home-text:active,
.navbar-home-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-about-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-about-text:active,
.navbar-about-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-members-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-members-text:active,
.navbar-members-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-schedule-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-schedule-text:active,
.navbar-schedule-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-events-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-events-text:active,
.navbar-events-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-contact-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-contact-text:active,
.navbar-contact-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-halo-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-halo-text:active,
.navbar-halo-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-signin-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-signin-text:active,
.navbar-signint-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-signup-text:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-signup-text:active,
.navbar-signup-text:focus {
  box-shadow: 2px 2px 6px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

.navbar-halo-logo:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
}

.navbar-halo-logo:active,
.navbar-halo-logo:focus {
  box-shadow: 2px 2px 10px var(--glow), -2px -2px 6px var(--glow);
  transform: scale(0.97);
  transition: 0.25s;
}

@media screen and (max-width: 500px) {
  .navbar-user-profile-logo {
    width: 40px;
  }
}

@media screen and (max-width: 380px) {
  .navbar-user-profile-logo {
  }
}
