body {
  line-height: normal;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
h1 {
  margin: 0;
}
h1 {
  margin: 0;
}
h3 {
  margin: 0;
}
p {
  margin: 0;
}
a {
  color: unset;
  text-decoration: none;
}

@font-face {
  font-family: "Akira";
  src: url("/src//assets//Fonts/Akira\ Expanded\ Demo.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --bg-img: url("./assets/BGs/BG\ Plain.png");
  --bg-img-vertical: url("./assets/BGs/BG\ Plain\ -\ vertical.png");
  --font-family-akira: "Akira", sans-serif;
  --font-family-manrope: "Manrope", sans-serif;
  --font-family-montserrat: "Montserrat", sans-serif;
  --font-family-source: "Source Sans Pro", sans-serif;
  --font-family-redhatdisplay: "Red Hat Display", sans-serif;
  --font-family-space: "Space Grotesk", sans-serif;
  --font-family-mova: "Mova", sans-serif;
  --fg-color: #ff6b00;
  --bg-color: #1b1f23;

  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #272c31;
  --color-bg2: #ff6b00;
  --color-menu: #071427;
  --color-footer: #031b34;

  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;

  --font-source-sans-pro: "Source Sans Pro";
  --font-red-hat-display: "Red Hat Display";
  --font-space-grotesk: "Space Grotesk";
  --font-montserrat-alternates: "Montserrat Alternates";

  --font-size-xl: 20px;
  --font-size-6xl: 25px;
  --font-size-5xl: 24px;
  --font-size-13xl: 32px;
  --font-size-15xl: 34px;
  --font-size-11xl: 30px;
  --font-size-61xl: 80px;
  --font-size-41xl: 60px;
  --font-size-21xl: 40px;
  --font-size-17xl: 36px;

  --font-color: white;

  --color-gray-100: #12131e;
  --events-cards: rgba(30, 30, 44, 0.7);
  --color-gray-200: rgba(18, 19, 30, 0.9);
  --color-gray-300: rgba(133, 132, 135, 0.35);
  --color-white: #fff;
  --color-midnightblue: #442077;
  --color-iobutton: var(--fg-color);
  --color-border: #c8ccd4;

  --color-dimgray-100: #4e4e59;
  --color-dimgray-200: #6a6a79;
  --glow: rgba(78, 78, 89, 0.5);
  --black-drop-shadow: rgba(0, 0, 0, 0.2);
  --glow-white: #d6d6d6;
  --gg: #888fff;
  --color-transparent: #ffffff00;
  --color-footer: #12131e;
  --gap-3xs: 10px;
  --gap-131xl: 150px;
  --gap-11xl: 30px;
  --gap-41xl: 60px;
  --gap-21xl: 40px;

  --padding-3xs: 10px;

  --br-8xs: 5px;

  --font-size-5xl: 1.5rem;
  --font-size-21xl: 2.5rem;
  --font-size-17xl: 2.25rem;
  --font-size-xl: 1.25rem;
  --font-size-6xl: 1.56rem;

  --button-glow: rgba(133, 132, 135, 0.35);
  --member-plaque: rgba(18, 19, 30, 0.918);
  --color-gray-100: rgba(255, 255, 255, 0.75);
  --gg: #888fff;
  --glow-2: #fff;
  --color-dimgray-100: #4e4e59;
  --glow: rgba(78, 78, 89, 0.5);
  --button: #442077;

  --gap-xl: 1.25rem;

  --padding-11xl: 1.88rem;

  --br-3xs: 10px;
  --br-31xl: 50px;
  --br-8xs: 5px;
}
